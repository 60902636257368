export const notificationCategories = {
  tickets: {
    unreadMessages: {
      title: "New unread messages in Concierge",
      icon: "envelope",
      link: "/concierge",
    },
    newTickets: {
      title: "New tickets created in Concierge",
      icon: "envelope",
      link: "/concierge",
    },
  },
  profile: {},
  announcement: {
    generic: {},
  },
};

export class Notification {
  constructor({
    title,
    message,
    category,
    subcategory,
    icon,
    timestamp = new Date(),
    action,
    meta,
    unread = true,
    link,
  }) {
    if (category && subcategory) {
      if (!title)
        title = notificationCategories[category][subcategory]["title"];
      if (!icon) icon = notificationCategories[category][subcategory]["icon"];
      if (!link) link = notificationCategories[category][subcategory]["link"];
    }
    this.id = category + "_" + subcategory;
    this.title = title;
    this.message = message;
    this.category = category;
    this.timestamp = timestamp;
    this.action = action;
    this.meta = meta;
    this.icon = icon;
    this.unread = unread;
    this.link = link;
  }
}

const notificationUtils = {
  Notification,
  notificationCategories,
};

export default notificationUtils;
