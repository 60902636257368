import React from "react";
import SiteNav from "./SidebarNav";
import SiteHeader from "../Header";
import PageTitle from "./PageTitle";
import Breadcrumbs from "./Breadcrumbs";
import { ProvideNotifications } from "../../hooks/use-notifications";

const DashboardShell = ({ children }) => {
  return (
    <div className="wrap">
      <main className="main-wrapper">
        <section className="app-shell">
          <ProvideNotifications>
            <SiteHeader />
            <SiteNav />
          </ProvideNotifications>
        </section>
        <section className="component-wrapper">
          <article className="component-inner">
            <header className="d-flex align-items-center justify-content-between">
              <PageTitle />
              <Breadcrumbs />
            </header>
            {children}
          </article>
        </section>
      </main>
    </div>
  );
};

export default DashboardShell;
