import React, { useState } from "react";
import { Transition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavItem from "./NavItem";
import AddContent from "./AddContent";

import { useNav } from "../../hooks/use-routes";

const SidebarNav = () => {
  const navItems = useNav();
  const [openAddWindow, setOpenAddWindow] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const icon = expanded ? "angle-double-left" : "angle-double-right";

  return (
    <CSSTransition classNames="expand" in={expanded} timeout={350}>
      <aside className="sidebar-nav-wrapper">
        <nav className="navbar sidebar-nav d-none d-sm-flex flex-column justify-content-between">
          <ul className="nav flex-column justify-content-start">
            {navItems.map((item) => (
              <NavItem
                key={item.location}
                name={item.name}
                location={item.location}
                icon={item.nav.icon}
                highlight={item.highlight}
              />
            ))}
          </ul>

          <div
            className="nav-expanded"
            style={{
              background: "#272c31",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: 30,
              height: 30,
              borderRadius: "100%",
              position: "absolute",
              bottom: 15,
              right: -15,
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              style={{ color: "white" }}
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </nav>

        <Transition in={openAddWindow} timeout={0}>
          {(state) => (
            <div className={`fading fading-${state}`}>
              <AddContent onClose={() => setOpenAddWindow(false)} />
            </div>
          )}
        </Transition>
      </aside>
    </CSSTransition>
  );
};

export default SidebarNav;
