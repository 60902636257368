import React, { useEffect } from "react";
import {
  Nav,
  Dropdown,
  NavItem,
  NavLink,
  Badge,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useNotifications } from "../../../hooks/use-notifications";
import { useSocketIoClient } from "../../../hooks/use-socket-io-client";
import styles from "./Notifications.module.scss";
import bellMp3 from "../../../audio/bell-2x.mp3";

const DivWrapper = ({ children, to, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

const Notification = ({
  id,
  icon = "check-circle",
  message,
  unread,
  link,
  onDismiss,
  dismissable,
}) => {
  const color = unread ? "white" : "muted";

  const ColContainer = link ? Link : DivWrapper;

  return (
    <Row noGutters>
      <Col>
        <ColContainer
          className={`d-flex notification row text-${color}`}
          to={(location) => {
            return {
              ...location,
              pathname: link,
              state: {
                fromNotification: id,
              },
            };
          }}
        >
          <Col xs="2">
            <FontAwesomeIcon icon={icon} style={{ fontSize: 20 }} />
          </Col>
          <Col xs="10">
            <p className="mb-0">{message}</p>
          </Col>
        </ColContainer>
      </Col>

      {dismissable && (
        <Col xs="1">
          <Button
            size="sm"
            variant="outline-white"
            className={styles["dismiss-button"]}
            onClick={() => onDismiss(id)}
          >
            <FontAwesomeIcon icon="times" className="text-white" />
          </Button>
        </Col>
      )}
    </Row>
  );
};

const Notifications = () => {
  const {
    notifications,
    addNotification,
    readNotification,
    removeNotification,
  } = useNotifications();

  const client = useSocketIoClient();

  useEffect(() => {
    const setupSubscription = () => {
      if (!client) return;
      client.subscribe("message", handleTicketData);
    };

    const handleTicketData = (packet) => {
      try {
        if (typeof packet === "string") packet = JSON.parse(packet);
      } catch (e) {
        console.error(e);
        return;
      }
      const { commandType, data } = packet;

      let notification = {
        category: "tickets",
        subcategory: "unreadMessages",
        timestamp: new Date(),
        meta: data,
      };

      switch (commandType) {
        case "TICKET_MESSAGES_UPDATED":
          break;

        case "TICKET_CREATED":
          notification.subcategory = "newTickets";
          break;

        case "TICKET_UPDATED":
          notification = null;
          break;
        default:
          notification = null;
          break;
      }

      if (notification) {
        // play sound
        const audio = new Audio(bellMp3);
        audio.play();
        addNotification(notification);
      }
    };

    setupSubscription();

    return () => client && client.unsubscribe("message", handleTicketData);
  }, [addNotification, client]);

  const unread = notifications.filter((n) => n.unread === true);

  return (
    <div className="user-notifications d-flex align-items-center">
      <Nav>
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink} className={styles["dropdown-toggle"]}>
            <span className="text-muted mr-1">
              <span className="notification-icon">
                <FontAwesomeIcon className={styles["icon"]} icon="bell" />
              </span>
              {unread.length > 0 && (
                <Badge variant="primary" className={styles["badge"]}>
                  {unread.length}
                </Badge>
              )}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles["menu"]}>
            <h6 className={styles["notification-headline"]}>Notifications</h6>
            {notifications.length === 0 ? (
              <Notification
                message="No new notifications."
                icon="check-circle"
                unread={false}
              />
            ) : (
              notifications.map((notification) => (
                <Notification
                  id={notification.id}
                  key={notification.id}
                  message={notification.title}
                  icon={notification.icon}
                  unread={notification.unread}
                  link={notification.link}
                  timestamp={notification.timestamp}
                  onDismiss={removeNotification}
                  onRead={readNotification}
                  dismissable
                />
              ))
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </div>
  );
};

export default Notifications;
