import Kohost from "@kohost/api-client";
import { User } from "../utils/users";

export async function userLogin(email, password) {
  const response = await Kohost.Auth.loginUser(email, password);
  return response.status >= 200 && response.status < 400;
}

export async function resetPassword(userID, password, token) {
  return await Kohost.Auth.resetPassword(userID, password, token);
}

export function logout() {
  return Kohost.Auth.logout();
}

export function getCurrentUser() {
  try {
    const user = Kohost.getCurrentUser();
    if (user) return new User(user);
    else return null;
  } catch (ex) {
    console.log(ex);
    return null;
  }
}

export function getJwt(key = null) {
  let token;
  if (key) {
    // eslint-disable-next-line no-undef
    token = localStorage.getItem(key);
  } else {
    // eslint-disable-next-line no-undef
    token = localStorage.getItem(userKey);
  }

  return token;
}

export function setJwt(key, value) {
  // eslint-disable-next-line no-undef
  localStorage.setItem(key, value);
}

export function isAdminUser() {
  try {
    return (
      getCurrentUser()?.type === "Admin" ||
      getCurrentUser()?.type === "SuperAdmin"
    );
  } catch (error) {
    return false;
  }
}

export function isSuperAdmin() {
  try {
    return getCurrentUser()?.type === "SuperAdmin";
  } catch (error) {
    return false;
  }
}

export function isManager() {
  try {
    return getCurrentUser()?.type === "Manager";
  } catch (error) {
    return false;
  }
}

export function isUser() {
  try {
    return getCurrentUser()?.type === "User";
  } catch (error) {
    return false;
  }
}

const authUitls = {
  logout,
  getCurrentUser,
  getJwt,
  setJwt,
  isAdminUser,
  isSuperAdmin,
  resetPassword,
  isManager,
  isUser,
};

export default authUitls;
