import React, { useContext, createContext } from "react";
import Kohost from "@kohost/api-client";
import KohostSocketIo from "@kohost/socket.io-client";

const socketIoContext = createContext();

function getApiUrl() {
  // add api the hostname
  // eg: if the current site is https://vu.kohost.app, the api url will be https://api.vu.kohost.app
  const hostname = window.location.hostname;
  const map = {
    "vu.admin.kohost.app": "api.vu.kohost.app",
    "dcslv.admin.kohost.app": "api.dcslv.kohost.app",
  };

  return `https://${map[hostname]}/users`;
}

export function ProvideSocketIoClient({ children }) {
  const client = useProvideSocketIoClient();
  return (
    <socketIoContext.Provider value={client}>
      {children}
    </socketIoContext.Provider>
  );
}

export const useSocketIoClient = () => {
  return useContext(socketIoContext);
};

function useProvideSocketIoClient() {
  const user = Kohost.getCurrentUser();
  const config = {
    url: getApiUrl(),
    token: Kohost.getAuthToken(),
    tenant: Kohost.config.tenant,
    user: user,
    errHandler: handleConnectError,
  };
  const client = new KohostSocketIo(config);

  Kohost.config.update({
    onNewToken: (token) => client.updateToken(token),
  });

  client.on("connect", () => {
    console.log("Kohost Socket.io client connected");
  });

  client.on("disconnect", () => {
    console.log("Kohost Socket.io client disconnected");
  });
  return client;
}

function handleConnectError(err) {
  const messages = [
    "Authentication error, no token provided",
    "Token is not valid",
  ];
  if (messages.includes(err.message)) {
    if (this.reconnectAttempt <= 1) {
      const token = Kohost.getAuthToken();
      this.updateToken(token);
    } else {
      Kohost.Auth.requestNewTokens().then(() => {
        this.updateToken(Kohost.getAuthToken());
      });
    }
  } else {
    console.log(err);
  }
}
