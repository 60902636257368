import React from "react";

const Status = ({ state = "active", text, Tag = "div" }) => {
  return (
    <Tag className="status">
      <span className={`status-icon ${state}`}></span>
      {text}
    </Tag>
  );
};

export default Status;
