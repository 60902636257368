import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { Notification } from "../utils/notifications";
import { useAuth } from "./use-auth";
import { useLocalStorage } from "./use-local-storage";

const notificationsContext = createContext();

const notifLsKey = "current-user-notifications";

export function ProvideNotifications({ children }) {
  const notifications = useProvideNotifications();
  return (
    <notificationsContext.Provider value={notifications}>
      {children}
    </notificationsContext.Provider>
  );
}

export const useNotifications = () => {
  return useContext(notificationsContext);
};

export const useProvideNotifications = () => {
  const [notifications, saveNotifications] = useLocalStorage(notifLsKey, []);
  const { state: locationState } = useLocation();
  const { currentUser } = useAuth();

  const clearNotifications = () => {
    saveNotifications([]);
  };

  const addNotification = (notificationData) => {
    const { category, subCategory } = notificationData;
    const exists = notifications.find(
      (n) => n.category === category && n.subCategory === subCategory
    );

    if (!exists) {
      const notification = new Notification(notificationData);
      const combined = [...notifications, notification];
      saveNotifications(combined);
    } else {
      unreadNotification(exists.id);
    }
  };

  const readNotification = useCallback((id) => {
    const existingIdx = notifications.findIndex((n) => n.id === id);
    const existingNotifications = [...notifications];
    const updated = { ...existingNotifications[existingIdx] };
    updated.unread = false;
    existingNotifications[existingIdx] = { ...updated };
    saveNotifications(existingNotifications);
    // eslint-disable-next-line
  }, []);

  const unreadNotification = useCallback((id) => {
    const existingIdx = notifications.findIndex((n) => n.id === id);
    const existingNotifications = [...notifications];
    const updated = { ...existingNotifications[existingIdx] };
    if (updated.unread) return;
    updated.unread = true;
    existingNotifications[existingIdx] = { ...updated };
    saveNotifications(existingNotifications);
    // eslint-disable-next-line
  }, []);

  const removeNotification = (id) => {
    const existingIdx = notifications.findIndex((n) => n.id === id);
    const existing = [...notifications];
    existing.splice(existingIdx, 1);
    saveNotifications(existing);
  };

  const categoryNotifications = (category) => {
    if (!category) return [];
    return notifications.filter((n) => n.category === category);
  };

  useEffect(() => {
    if (currentUser && currentUser.notifications) {
      saveNotifications(currentUser.notifications);
    }
  }, [currentUser, saveNotifications]);

  useEffect(() => {
    if (locationState?.fromNotification)
      readNotification(locationState.fromNotification);
  }, [locationState, readNotification]);

  return {
    notifications,
    categoryNotifications,
    clearNotifications,
    addNotification,
    readNotification,
    removeNotification,
  };
};
