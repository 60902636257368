import React from "react";
import { ReactComponent as GenericUserIcon } from "../../img/default-user-icon.svg";
import Image from "./Image";

function getUserPhotoUrl(user) {
  if (!user) return null;
  if (user.avatar) return user.avatar;
  return null;
}

const ProfilePicture = ({ user, ...rest }) => {
  if (!user) return null;
  const image = getUserPhotoUrl(user);
  if (!image) return <GenericUserIcon {...rest} className="profile-photo" />;
  return (
    <Image
      {...rest}
      className="profile-photo"
      src={user.avatar}
      alt={user.firstName}
    />
  );
};

export default ProfilePicture;
