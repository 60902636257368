import React, { useState, useEffect, useContext, createContext } from "react";
import authUitls from "../services/auth";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const authenticatedUser = authUitls.getCurrentUser();
    if (authenticatedUser) {
      setUser(authenticatedUser);
    } else {
      setUser(false);
    }
  }, []);

  // Return the user object and auth methods
  return {
    currentUser: user,
  };
}
