import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./LoadingSpinner.module.scss";

const LoadingSpinner = ({
  width = "100%",
  height = 350,
  fullscreen = false,
}) => {
  const containerCx = fullscreen ? "container-fullscreen" : "container";
  return (
    <div className={styles[containerCx]} style={{ width, height }}>
      <Spinner className={styles["spinner"]} animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LoadingSpinner;
