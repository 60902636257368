import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useAuthRoutes, usePublicRoutes } from "./hooks/use-routes";
import AuthRoute from "./components/common/AuthRoute";
import DashboardShell from "./components/common/DashboardShell";
import LoadingSpinner from "./components/common/LoadingSpinner";

const Routes = () => {
  const authRoutes = useAuthRoutes();
  const publicRoutes = usePublicRoutes();
  return (
    <Suspense fallback={<LoadingSpinner fullscreen />}>
      <Switch>
        {publicRoutes.map((route) => (
          <Route
            key={route.location}
            path={route.location}
            component={route.component}
          />
        ))}
        <Route path="/">
          <DashboardShell>
            <Switch>
              {authRoutes.map((route) => (
                <AuthRoute
                  key={route.location}
                  path={route.location}
                  component={route.component}
                />
              ))}
              <Redirect to="/not-found" />
            </Switch>
          </DashboardShell>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
