import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faUser,
  faPhone,
  faEnvelope,
  faPlus,
  faSignOutAlt,
  faPencilAlt,
  faBars,
  faTimes,
  faCog,
  faMicrochip,
  faPlug,
  faSync,
  faPowerOff,
  faTrash,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faArrowCircleRight,
  faUserCircle,
  faLeaf,
  faHotel,
  faHome,
  faCopy,
  faMobileAlt,
  faMapSigns,
  faSignal,
  faLightbulb,
  faThermometerHalf,
  faSave,
  faServer,
  faCalendarAlt,
  faCloudUploadAlt,
  faWifi,
  faDollarSign,
  faConciergeBell,
  faPaperPlane,
  faCheck,
  faClock,
  faReply,
  faSmile,
  faArrowUp,
  faArrowDown,
  faCheckCircle,
  faExclamation,
  faExclamationCircle,
  faHistory,
  faChevronDown,
  faBell,
  faExpand,
  faInfoCircle,
  faLock,
  faArrowRight,
  faCaretDown,
  faStop,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faMinusCircle,
  faBuilding,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendarCheck,
  faCalendarMinus,
  faClock as faClockRegular,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faMinusCircle,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faStop,
  faInfoCircle,
  faSearch,
  faUser,
  faPhone,
  faEnvelope,
  faPlus,
  faSignOutAlt,
  faPencilAlt,
  faBars,
  faTimes,
  faCog,
  faMicrochip,
  faServer,
  faPlug,
  faSync,
  faPowerOff,
  faTrash,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faArrowCircleRight,
  faUserCircle,
  faLeaf,
  faCalendarCheck,
  faCalendarMinus,
  faHotel,
  faHome,
  faCopy,
  faMobileAlt,
  faMapSigns,
  faSignal,
  faLightbulb,
  faThermometerHalf,
  faSave,
  faCalendarAlt,
  faCloudUploadAlt,
  faWifi,
  faDollarSign,
  faConciergeBell,
  faPaperPlane,
  faCheck,
  faClock,
  faReply,
  faSmile,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faCheckCircle,
  faExclamation,
  faExclamationCircle,
  faClockRegular,
  faHistory,
  faChevronDown,
  faBell,
  faExpand,
  faLock,
  faCaretDown,
  faBuilding,
  faTerminal
);
