import React, { useState, useEffect, useContext, createContext } from "react";
import Kohost from "@kohost/api-client";
import LoadingSpinner from "../components/common/LoadingSpinner";
import useBodyClass from "./use-body-class";

const configContext = createContext({});

export function ProvideConfig({ children }) {
  const config = useProvideConfig();
  if (!config || Object.keys(config).length === 0)
    return <LoadingSpinner fullscreen height="100%" />;
  return (
    <configContext.Provider value={config}>{children}</configContext.Provider>
  );
}

export const useConfig = () => {
  return useContext(configContext);
};

function useProvideConfig() {
  const [config, setConfig] = useState({});

  const bodyClass =
    config.structure?.type && `structure-type-${config.structure.type}`;

  useBodyClass(bodyClass);

  useEffect(() => {
    const createAppConfig = (config) => {
      setConfig(config);
    };
    const fetchAppConfig = async () => {
      const configRequest = await Kohost.get("/config");
      const appConfig = configRequest.data[0];
      return appConfig;
    };
    fetchAppConfig().then((config) => {
      createAppConfig(config);
    });
  }, []);

  return config;
}

export default configContext;
