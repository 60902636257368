import React from "react";
import Kohost from "@kohost/api-client";
import Routes from "./routes";
import { ToastContainer as NotificationContainer } from "react-toastify";
import { ProvideAuth } from "./hooks/use-auth";
import { ProvideConfig } from "./hooks/use-config";
import { ProvideSocketIoClient } from "./hooks/use-socket-io-client";
import NewVersion from "./components/common/NewVersion";
import "./icons";

function getApiUrl() {
  // add api the hostname
  // eg: if the current site is https://vu.kohost.app, the api url will be https://api.vu.kohost.app
  const hostname = window.location.hostname;
  const map = {
    "vu.admin.kohost.app": "api.vu.kohost.app",
    "dcslv.admin.kohost.app": "api.dcslv.kohost.app",
  };

  return `https://${map[hostname]}/v2`;
}

function initKohost() {
  const getTenant = () => {
    if (process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_TENANT || "kohost-dev";
    } else {
      const subdomain = window.location.host.split(".")[1]
        ? window.location.host.split(".")[0]
        : null;
      return subdomain;
    }
  };
  Kohost.config.update({
    url: getApiUrl(),
    onLoginRequired: function () {
      if (!window.location.href.includes("login")) window.location = "/login";
    },
    lsAuthTokenKey: "admin-x-auth-token",
    lsRefreshTokenKey: "admin-x-refresh-token",
    lsUserKey: "kohost-admin-user",
    tenant: getTenant(),
  });
}

initKohost();

function App() {
  return (
    <ProvideConfig>
      <ProvideAuth>
        <ProvideSocketIoClient>
          <Routes />
          <NewVersion />
          <NotificationContainer />
        </ProvideSocketIoClient>
      </ProvideAuth>
    </ProvideConfig>
  );
}

export default App;
