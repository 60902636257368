import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const toggleFs = () => {
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};

const ToggleFullscreen = () => {
  return (
    <div className="d-flex align-items-center">
      <button
        type="button"
        onClick={() => {
          toggleFs();
        }}
        className="btn text-muted"
        data-toggle="fullscreen"
      >
        <FontAwesomeIcon icon="expand" />
      </button>
    </div>
  );
};

export default ToggleFullscreen;
