import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { useRoutes } from "../../hooks/use-routes";

const PageTitle = () => {
  const [title, setTitle] = useState();

  const routes = useRoutes();

  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    const matchedRoute = routes.find((route) =>
      matchPath(pathname, { path: route.location })
    );
    if (matchedRoute) setTitle(matchedRoute.name);
    else setTitle(false);
  }, [pathname, routes]);
  if (!title) return null;
  return <h1 className="page-title">{title}</h1>;
};

export default PageTitle;
