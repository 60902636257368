import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import kebabCase from "lodash/kebabCase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Status from "./Status";

const NavItem = ({ name, location, icon, highlight }) => {
  const props = {
    className: "nav-link d-flex justify-content-between",
    to: location,
    activeClassName: "active",
  };

  if (location === "/") {
    props.exact = true;
  }
  const navItemClass = classNames("nav-item", `nav-item-${kebabCase(name)}`);
  return (
    <li className={navItemClass}>
      <NavLink {...props}>
        <span className="nav-icon-wrapper">
          {icon && (
            <span className="nav-icon">
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
          {name && <span className="nav-text">{name}</span>}
        </span>
        {highlight && <Status state="active" Tag="span" />}
      </NavLink>
    </li>
  );
};

export default NavItem;
