import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import auth from "../../services/auth";

const Overlay = ({ onClose }) => {
  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.65)",
    zIndex: 10,
  };
  return <div className="body-overlay" onClick={onClose} style={style} />;
};

const AddContent = ({ onClose }) => {
  const history = useHistory();

  const navigate = (path) => {
    history.push(path);
    onClose();
  };
  return (
    <>
      <div className="add-content-window">
        <div className="close-window" onClick={onClose}>
          <FontAwesomeIcon icon="times" />
        </div>
        <div className="add-content-window--content">
          <h5>Create New</h5>
          <ActionButton
            name="Guest"
            icon="user"
            onClick={() => navigate("/user/add?type=Guest")}
          />
          <ActionButton
            name="Push Notification"
            icon="envelope"
            onClick={() => navigate("/marketing")}
          />
          {auth.isAdminUser() && (
            <>
              <ActionButton
                icon="server"
                name="Controller"
                onClick={() => navigate("/admin-settings")}
              />
              <ActionButton
                icon="hotel"
                name="Hotel Room"
                onClick={() => navigate("/hotel-rooms")}
              />
            </>
          )}
        </div>
      </div>
      <Overlay onClose={onClose} />
    </>
  );
};

const ActionButton = ({ icon, name, onClick = function () {} }) => {
  return (
    <Button className="action-button d-block w-100 text-left" onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
      <span className="action-button--action-name ml-2">{name}</span>
    </Button>
  );
};

export default AddContent;
