import React, { useState, useEffect } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { uniqBy } from "lodash";
import styles from "./Breadcrumbs.module.scss";
import { useRoutes, useHomePageRoute } from "../../../hooks/use-routes";

const Breadcrumbs = () => {
  const [items, setItems] = useState([]);
  const routes = useRoutes();
  const homePage = useHomePageRoute();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const home = { name: homePage.name, link: homePage.location };
    const activeMatched = routes.find((route) =>
      matchPath(pathname, { path: route.location })
    );

    const levels = activeMatched.location.split("/");

    const totalBc = levels.length;

    const bcItems = [home];

    let search = "/";

    levels.forEach((level, i) => {
      const count = i + 1;
      if (count === 1) return;
      const ts = count < totalBc ? "/" : "";
      search = search + level + ts;
      const matched = routes.find((route) =>
        matchPath(search, { path: route.location })
      );
      if (matched && count <= totalBc) {
        const bcObject = { name: matched.name, link: matched.location };
        bcItems.push(bcObject);
      }
    });

    const filtered = uniqBy(bcItems, "link");
    setItems(filtered);
  }, [pathname, routes, homePage.location, homePage.name]);

  if (items.length <= 1) return null;
  return (
    <Breadcrumb className={styles["list"]}>
      {items.map((item, idx) => (
        <Breadcrumb.Item
          linkAs={Link}
          key={item.link}
          linkProps={{ to: item.link }}
          active={idx + 1 === items.length}
          className={styles["link"]}
        >
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
