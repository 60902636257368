import React from "react";
import Logo from "../common/Logo";
import Notifications from "./Notifications";
import ToggleFullscreen from "./ToggleFullscreen";
import UserNav from "./UserNav";

const Header = () => {
  return (
    <header className="site-header container-fluid header mb-1 py-0 d-flex align-items-stretch justify-content-between">
      <div className="header-logo logo-container">
        <Logo />
      </div>
      <div className="header-content d-flex align-items-stretch">
        <ToggleFullscreen />
        <Notifications />
        <UserNav />
      </div>
    </header>
  );
};

export default Header;
