import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import * as serviceWorker from "../../../serviceWorkerRegistration";
import styles from "./NewVersion.module.scss";

const NewVersion = () => {
  const [isNew, setNew] = useState(false);
  const [registration, setRegistration] = useState(null);

  const refresh = () => {
    skipWaiting();
    window.location.reload();
  };

  const skipWaiting = () => {
    if (registration)
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
  };

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setRegistration(registration);
        setNew(true);
      },
    });
  }, []);

  return (
    <div className={styles["container"]}>
      <CSSTransition
        in={isNew}
        appear={true}
        classNames="notification"
        timeout={{
          appear: 0,
          enter: 350,
          exit: 350,
        }}
        unmountOnExit
        mountOnEnter
      >
        {(state) => {
          const style = {
            entering: "notification-entering",
            entered: "notification-entered",
            exiting: "notification-exiting",
            exited: "notification-exited",
          };

          let cx = style[state];

          return (
            <div className={styles[cx]}>
              <p className={styles["text"]}>New Version Available!</p>
              <Button className={styles["button"]} onClick={refresh}>
                Refresh
              </Button>
              <Button
                className={styles["button-dismiss"]}
                onClick={() => setNew(false)}
              >
                <FontAwesomeIcon icon="times" />
              </Button>
            </div>
          );
        }}
      </CSSTransition>
    </div>
  );
};

export default NewVersion;
