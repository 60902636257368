import React from "react";
import { Nav, Dropdown, NavItem, NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/use-auth";
import ProfilePicture from "../../common/ProfilePicture";

const UserNav = () => {
  const { currentUser } = useAuth();
  if (!currentUser) return null;

  const ddCx = "text-muted";
  return (
    <div className="user-nav d-flex align-items-center">
      <div className="header-dropdown">
        <Nav>
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink}>
              <span className="text-muted mr-2">
                <span className="mr-2">
                  <ProfilePicture
                    user={currentUser}
                    className="mr-2"
                    style={{ maxWidth: 30 }}
                  />
                </span>
                {currentUser.email}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-gray-dark">
              {currentUser.isAdmin() && (
                <Dropdown.Item className={ddCx} href="/admin-settings">
                  <FontAwesomeIcon icon="cog" /> Admin
                </Dropdown.Item>
              )}
              <Dropdown.Item className={ddCx} href="/profile">
                <FontAwesomeIcon icon="user" /> Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className={ddCx} href="/logout">
                <FontAwesomeIcon icon="sign-out-alt" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </div>
    </div>
  );
};

export default UserNav;
