import { useConfig } from "./use-config";
import { useState, useEffect } from "react";

export function useGroupConfig() {
  const [slug, setSlug] = useState("groups");
  const [name, setName] = useState({
    singular: "Group",
    plural: "Groups",
  });
  const config = useConfig();
  const structureType = config?.structure?.type;

  const groupConfig = config?.app?.groups;

  useEffect(() => {
    if (groupConfig.customName) {
      setName(groupConfig.customName);
    } else if (structureType === "hospitality") {
      setSlug("hotel-rooms");
      setName({ singular: "Hotel Room", plural: "Hotel Rooms" });
    }
  }, [structureType, groupConfig.customName]);

  return { slug, name };
}
