import { useEffect } from "react";

const addBodyClass = (name) => name && document.body.classList.add(name);
const removeBodyClass = (name) => name && document.body.classList.remove(name);

export default function useBodyClass(className) {
  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className);
    };
  }, [className]);
}
