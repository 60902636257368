import { useState, useEffect, lazy } from "react";
import auth from "../services/auth";
import { useConfig } from "./use-config";
import { useGroupConfig } from "./use-group-config";
const Dashboard = lazy(() => import("../components/Dashboard"));
const Login = lazy(() => import("../components/Login/Login"));
const Logout = lazy(() => import("../components/Login/Logout"));
const ResetPassword = lazy(() => import("../components/Login/ResetPassword"));
const NotFound = lazy(() => import("../components/NotFound"));
const Profile = lazy(() => import("../components/Profile/Profile"));
const Groups = lazy(() => import("../components/Groups/Groups"));
const Guests = lazy(() => import("../components/Guests"));
const Users = lazy(() => import("../components/Users"));
const Group = lazy(() => import("../components/Groups/Group"));
const Energy = lazy(() => import("../components/Energy/EnergyDashboard"));
const Settings = lazy(() => import("../components/Settings/SettingsDashboard"));
const Messaging = lazy(() =>
  import("../components/Messaging/MessagingDashboard")
);
const Concierge = lazy(() =>
  import("../components/Concierge/ConciergeDashboard")
);
const Configuration = lazy(() => import("../components/Configuration"));
const UserForm = lazy(() => import("../components/Users/UserForm"));
const ControllerDashboard = lazy(() =>
  import("../components/Controllers/ControllerDashboard")
);

function isLoggedIn() {
  // this function won't even run if the user is logged out. The <AuthRoute /> component
  // checks for an active token in localStorage. If it doesn't exist, then the user will
  // be directed to the login page
  return true;
}

const coreRoutes = [
  {
    name: "Login",
    location: "/login",
    component: Login,
  },
  {
    name: "Logout",
    location: "/logout",
    component: Logout,
  },
  {
    name: "Forgot Password",
    location: "/reset-password",
    component: ResetPassword,
  },
  {
    name: "Page Not Found ",
    location: "/not-found",
    component: NotFound,
  },
  {
    name: "Settings",
    location: "/settings",
    nav: {
      order: 6,
      icon: "cog",
    },
    auth: {
      valid: () => auth.isSuperAdmin(),
    },
    component: Settings,
  },
  {
    name: "Configuration",
    location: "/configuration",
    nav: {
      order: 7,
      icon: "cog",
    },
    auth: {
      valid: () => auth.isSuperAdmin(),
    },
    component: Configuration,
  },
  {
    name: "Controllers",
    location: "/controllers",
    nav: {
      order: 8,
      icon: "microchip",
    },
    auth: {
      valid: () => auth.isSuperAdmin(),
    },
    component: ControllerDashboard,
  },
  {
    name: "Profile",
    location: "/profile",
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Profile,
  },
  {
    name: "Users",
    location: "/users",
    nav: {
      order: 2,
      icon: "user",
    },
    auth: {
      valid: () => !auth.isUser(),
    },
    component: Users,
  },
  {
    name: "Add User",
    location: "/user/add",
    auth: {
      valid: () => isLoggedIn(),
    },
    component: UserForm,
  },
  {
    name: "Edit User",
    location: "/user/:id/edit",
    auth: {
      valid: () => isLoggedIn(),
    },
    component: UserForm,
  },
  {
    name: "Energy",
    location: "/energy",
    nav: {
      order: 3,
      icon: "signal",
    },
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Energy,
  },
  {
    name: "Dashboard",
    location: "/",
    nav: {
      icon: "home",
      order: 1,
    },
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Dashboard,
  },
];

const hospitalityRoutes = [
  {
    name: "Edit User",
    location: "/hotel-rooms/:groupId/:userType/add",
    auth: {
      valid: () => isLoggedIn(),
    },
    component: UserForm,
  },
  {
    name: "Edit Hotel Room",
    location: "/hotel-rooms/:groupId",
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Group,
  },
  {
    name: "Hotel Rooms",
    location: "/hotel-rooms",
    nav: {
      order: 2,
      icon: "hotel",
    },
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Groups,
  },
  {
    name: "Guests",
    location: "/guests",
    nav: {
      order: 2,
      icon: "user",
    },
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Guests,
  },

  {
    name: "Marketing",
    location: "/marketing",
    nav: {
      order: 4,
      icon: "envelope",
    },
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Messaging,
  },
  {
    name: "Concierge",
    location: "/concierge",
    nav: {
      order: 5,
      icon: "concierge-bell",
      notifications: "tickets",
    },
    auth: {
      valid: () => isLoggedIn(),
    },
    component: Concierge,
  },
];

export function useNav() {
  const routes = useRoutes();
  return routes
    .filter((route) => route.nav)
    .filter((route) => {
      if (!route.auth) return true;
      else return route.auth.valid && route.auth.valid();
    })
    .sort((a, b) => a.nav.order - b.nav.order);
}

export function useRoutes() {
  const [routes, setRoutes] = useState(coreRoutes);
  const config = useConfig();
  const groupConfig = useGroupConfig();
  const structureType = config?.structure?.type;

  useEffect(() => {
    let nav = [];
    if (structureType === "hospitality") {
      nav = [...hospitalityRoutes, ...coreRoutes];
    }

    if (structureType === "commercial") {
      const commercialRoutes = generateCommercialRoutes({
        slug: groupConfig.slug,
        name: groupConfig.name,
      });
      nav = [...commercialRoutes, ...coreRoutes];
    }
    setRoutes([...nav]);
  }, [structureType, groupConfig.name, groupConfig.slug]);

  return routes;
}

export function usePublicRoutes() {
  return useRoutes().filter((route) => route.auth === undefined);
}

export function useAuthRoutes() {
  return useRoutes().filter((route) => route.auth !== undefined);
}

export function useHomePageRoute() {
  return useRoutes().find((route) => route.location === "/");
}

function generateCommercialRoutes(groupConfig) {
  const { slug, name: groupName } = groupConfig;
  const commercialRoutes = [
    {
      name: "Edit User",
      location: `/${slug}/:groupId/:userType/add`,
      auth: {
        valid: () => isLoggedIn(),
      },
      component: UserForm,
    },
    {
      name: `Edit ${groupName.singular}`,
      location: `/${slug}/:groupId`,
      auth: {
        valid: () => isLoggedIn(),
      },
      component: Group,
    },
    {
      name: groupName.plural,
      location: `/${slug}`,
      nav: {
        order: 2,
        icon: "building",
      },
      auth: {
        valid: () => isLoggedIn(),
      },
      component: Groups,
    },
    {
      name: "Announcements",
      location: "/announcements",
      nav: {
        order: 4,
        icon: "envelope",
      },
      auth: {
        valid: () => isLoggedIn(),
      },
      component: Messaging,
    },
  ];

  return commercialRoutes;
}
