import React from "react";
import { ReactComponent as Icon } from "../../img/kh-logo-white.svg";

const Logo = () => {
  return (
    <h1 className="logo">
      <Icon alt="Kohost" title="Kohost" />
    </h1>
  );
};

export default Logo;
